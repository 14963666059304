import {
  boundsToLocation,
  extractSearchQuery,
  geolocationToLocation,
  queryStringToBounds,
  queryStringToDates,
  queryStringToGuests,
  queryStringToSearchFilters,
  queryStringToSortOrder,
  searchCriteriaToSearchParameters,
  searchParametersToApiRequest,
  searchParametersToSearchQuery,
} from '~/lib/search/parameters'

import { COOKIES, RV_SEARCH_MIN_MAX } from '~/constants'

import type {
  SearchCoordinates,
  SearchLocation,
  SearchMinMaxFilter,
  SearchFilters,
  SearchDates,
  SearchGuests,
  SearchParameters,
  SearchUpdateParameters,
  SearchParsedGeocode,
  SearchCriteria,
} from '~/types/search'

export default defineNuxtPlugin(async () => {
  const { $captureError } = useNuxtApp()
  const route = useRoute()
  const geolocation = useGeolocationData()
  const googleMaps = await useGoogleMaps()
  const { query } = route
  const { geocode } = useGeocode()
  const router = useRouter()
  const { shouldRedirectToNuxt2App, nuxt2BaseUrl } = useRouteManager()

  const getDefaultParams = (): SearchParameters => ({
    filters: {
      rvCottage: false,
      delivery: false,
      rvPrice: {
        min: RV_SEARCH_MIN_MAX.rvPrice.min,
        max: RV_SEARCH_MIN_MAX.rvPrice.max,
      },
      rvWeight: {
        min: RV_SEARCH_MIN_MAX.rvWeight.min,
        max: RV_SEARCH_MIN_MAX.rvWeight.max,
      },
      rvLength: {
        min: RV_SEARCH_MIN_MAX.rvLength.min,
        max: RV_SEARCH_MIN_MAX.rvLength.max,
      },
      rvYear: {
        min: RV_SEARCH_MIN_MAX.rvYear.min,
        max: RV_SEARCH_MIN_MAX.rvYear.max,
      },
      rvBrand: '',
      petFriendly: false,
      instantBook: false,
      festivalFriendly: false,
      experienceNotRequired: false,
      superhost: false,
    },
    guests: {},
    bounds: {
      hasBounds: false,
    },
    dates: {
      dates: {},
    },
    location: {},
    sort: {
      value: '',
    },
  })

  const searchParameters = useState<SearchParameters>('searchParameters', () =>
    getDefaultParams(),
  )
  const showDiscoverDelivery = useState('showDiscoverDelivery', () => false)

  const preventNavigationDuplicatedAndGoTo = async ({
    path,
    query,
  }: { path?: string, query?: string } = {}) => {
    const newPath = path || route.path
    const shouldRedirect = shouldRedirectToNuxt2App(newPath)
    const newQuery = query ? `?${query}` : ''
    const newRoute = `${shouldRedirect ? nuxt2BaseUrl : ''}${newPath}${newQuery}`

    if (router.currentRoute.value.fullPath === newRoute) {
      return
    }

    if (path) {
      // TODO: remove external once search page is migrated to Nuxt3
      await navigateTo(newRoute, { external: shouldRedirect })
    }
    else {
      await navigateTo(newRoute, { replace: true })
    }
  }

  type SearchQuery = ReturnType<typeof searchParametersToSearchQuery>
  type TParams = Omit<SearchQuery, 'Amenities' | 'Types'> & {
    Amenities?: string
    Types?: string
  }

  const updateRoute = async (path: string) => {
    const searchQuery: SearchQuery = searchParametersToSearchQuery(
      searchParameters.value,
    )

    const params = { ...searchQuery } as unknown as TParams
    params.Amenities = searchQuery.Amenities?.length
      ? searchQuery.Amenities.join(',')
      : undefined
    params.Types = searchQuery.Types?.length
      ? searchQuery.Types.join(',')
      : undefined

    const filtered = Object.keys(params)
      .filter((x) => params[x as keyof TParams] != null)
      .reduce((acc: Partial<TParams>, x) => {
        if (
          params[x as keyof TParams] != null
          && params[x as keyof TParams] !== ''
        ) {
          // @ts-expect-error Types not defined yet.
          acc[x as keyof TParams] = String(params[x as keyof TParams])
        }
        return acc
      }, {})

    // Left the ts ignore, because this function works with the current implementation
    // not being necessary a second conversion
    // @ts-expect-error Types not defined yet.
    const query = new URLSearchParams(filtered)
      .toString()
      .replace(/=true(&|$)/g, '$1')

    const updateRoute = path && router.currentRoute.value.path !== path
    if (updateRoute) {
      await preventNavigationDuplicatedAndGoTo({ path, query })
    }
    else {
      await preventNavigationDuplicatedAndGoTo({ query })
    }
  }

  const clearBounds = () => {
    searchParameters.value.bounds.hasBounds = false
    searchParameters.value.bounds.east = undefined
    searchParameters.value.bounds.west = undefined
    searchParameters.value.bounds.north = undefined
    searchParameters.value.bounds.south = undefined
  }

  const updateLocationFields = ({
    searchable = false,
    placeId = undefined,
    mainText = undefined,
    secondaryText = undefined,
    types = [],
    center = undefined,
    bounds = undefined,
    city = undefined,
    region = undefined,
    country = undefined,
    fullName = undefined,
  }: SearchLocation = {}) => {
    searchParameters.value.location.searchable = searchable
    searchParameters.value.location.placeId = placeId
    searchParameters.value.location.mainText = mainText
    searchParameters.value.location.secondaryText = secondaryText
    searchParameters.value.location.types = types
    searchParameters.value.location.center = center
    searchParameters.value.location.bounds = bounds
    searchParameters.value.location.city = city
    searchParameters.value.location.region = region
    searchParameters.value.location.country = country
    searchParameters.value.location.fullName = fullName
  }

  const updateBounds = ({ east, west, north, south }: SearchCoordinates) => {
    if ((east || west) && (north || south)) {
      searchParameters.value.bounds.hasBounds = true
      searchParameters.value.bounds.east = east
      searchParameters.value.bounds.west = west
      searchParameters.value.bounds.north = north
      searchParameters.value.bounds.south = south

      const location = boundsToLocation('', searchParameters.value.bounds)

      if (location) {
        updateLocationFields(location)
      }
    }
  }

  const geocodedGooglePlace = async (placeId: string) => {
    if (!placeId || !googleMaps) {
      return
    }

    try {
      const result = await googleMaps.geocoder({
        placeId,
      })

      return parseGoogleGeocode({ geocode: result[0] })
    }
    catch (_error) {
      $captureError('No result returned from Google Geocode API.')
      return
    }
  }

  const parseGoogleGeocode = ({
    fullName,
    geocode,
  }: {
    fullName?: string
    geocode: google.maps.GeocoderResult
  }) => {
    const parsed: SearchParsedGeocode = {
      searchable: true,
      placeId: geocode.place_id,
      mainText: geocode.address_components[0].long_name,
      types: geocode?.types ?? undefined,
      center: geocode.geometry.location?.toJSON
        ? geocode.geometry.location.toJSON()
        : geocode.geometry.location,
      bounds: geocode.geometry.bounds?.toJSON
        ? {
            north: geocode.geometry.bounds?.getNorthEast().lat(),
            east: geocode.geometry.bounds?.getNorthEast().lng(),
            south: geocode.geometry.bounds?.getSouthWest().lat(),
            west: geocode.geometry.bounds?.getSouthWest().lng(),
          }
        : geocode.geometry.bounds,
      city: undefined,
      region: undefined,
      country: undefined,
      fullName: undefined,
    }

    geocode.address_components.forEach((addrComponent) => {
      if (addrComponent.types.includes('locality')) {
        parsed.city = addrComponent
      }
      else if (addrComponent.types.includes('administrative_area_level_1')) {
        parsed.region = addrComponent
      }
      else if (addrComponent.types.includes('country')) {
        parsed.country = addrComponent
      }
    })

    parsed.fullName
      = fullName
      ?? [
        parsed.city?.long_name,
        parsed.region?.short_name,
        parsed.country?.long_name,
      ]
        .filter((x) => x)
        .join(', ')

    return parsed
  }

  const parseGooglePlace = (
    place: google.maps.places.AutocompletePrediction,
  ) => {
    return {
      searchable: true,
      placeId: place?.place_id ?? undefined,
      mainText: place?.structured_formatting.main_text ?? undefined,
      secondaryText: place?.structured_formatting?.secondary_text ?? undefined,
      types: place?.types ?? undefined,
      fullName: place?.description ?? undefined,
    }
  }

  const clearLocation = () => {
    updateLocationFields()
    clearBounds()
  }

  const updateDates = ({ start, end }: SearchDates['dates']) => {
    searchParameters.value.dates.dates.start = start
    searchParameters.value.dates.dates.end = end
  }

  const updateGuests = ({
    adults,
    children,
    petFriendly = false,
  }: {
    adults?: SearchGuests['adults']
    children?: SearchGuests['children']
    petFriendly?: boolean
  } = {}) => {
    searchParameters.value.guests.adults = adults
    searchParameters.value.guests.children = children
    searchParameters.value.filters.petFriendly = petFriendly
  }

  const closeDiscoverDelivery = () => {
    showDiscoverDelivery.value = false

    const expires = new Date()
    expires.setDate(expires.getDate() + 60)

    const discoverCookie = useCookie(COOKIES.discoverDeliveryTooltip, {
      path: '/',
      expires,
    })

    discoverCookie.value = '1'
  }

  const updateFilter = <TKey extends keyof SearchFilters>(
    key: TKey,
    value: SearchFilters[TKey],
  ) => {
    let formattedValue = value

    // Reset Range values when values are defaults
    const emptyRange = {
      min: undefined,
      max: undefined,
    }

    /**
     * Checks if the value is the default value and sets the formattedValue to an empty range.
     */
    const setFormattedValue = (
      value: SearchMinMaxFilter,
      min: number,
      max: number,
    ) => {
      if (value?.min === min && value?.max === max) {
        (formattedValue as SearchMinMaxFilter) = emptyRange
      }
    }

    switch (key) {
      case 'rvPrice':
        setFormattedValue(
          value as SearchMinMaxFilter,
          RV_SEARCH_MIN_MAX.rvPrice.min,
          RV_SEARCH_MIN_MAX.rvPrice.max,
        )

        break

      case 'rvWeight':
        setFormattedValue(
          value as SearchMinMaxFilter,
          RV_SEARCH_MIN_MAX.rvWeight.min,
          RV_SEARCH_MIN_MAX.rvWeight.max,
        )

        break

      case 'rvLength':
        setFormattedValue(
          value as SearchMinMaxFilter,
          RV_SEARCH_MIN_MAX.rvLength.min,
          RV_SEARCH_MIN_MAX.rvLength.max,
        )

        break

      case 'rvYear':
        setFormattedValue(
          value as SearchMinMaxFilter,
          RV_SEARCH_MIN_MAX.rvYear.min,
          RV_SEARCH_MIN_MAX.rvYear.max,
        )

        break
    }

    searchParameters.value.filters[key] = formattedValue

    if (key === 'delivery' && formattedValue) {
      closeDiscoverDelivery()
    }
  }

  const searchFiltersHold: SearchFilters = {
    drivable: undefined,
    towable: undefined,
    amenities: undefined,
    rvCottage: false,
    delivery: false,
    rvPrice: { min: undefined, max: undefined },
    rvWeight: { min: undefined, max: undefined },
    rvLength: { min: undefined, max: undefined },
    rvYear: { min: undefined, max: undefined },
    petFriendly: false,
    instantBook: false,
    rvBrand: '',
    festivalFriendly: false,
    experienceNotRequired: false,
    superhost: false,
  }

  const updateFilters = (filters: SearchFilters) => {
    searchParameters.value.filters = filters

    // Have to loop through each parameter to update
    Object.entries(searchFiltersHold).forEach(([parameter, defaultValue]) => {
      updateFilter(
        parameter as keyof SearchFilters,
        filters[parameter as keyof SearchFilters] ?? defaultValue,
      )
    })
  }

  /**
   * @todo Are we sure about the reactivity caveat here?
   */
  const updateSortOrder = (value: string) => {
    // Added a nested value, because first level values aren't reactive
    searchParameters.value.sort.value = value
  }

  const updateParameters = async ({
    location,
    dates,
    guests,
    filters,
  }: SearchUpdateParameters) => {
    if (location?.placeId) {
      const geocode = await geocodedGooglePlace(location.placeId)

      if (geocode) {
        updateLocationFields({
          searchable: location?.searchable ?? geocode?.searchable,
          placeId: geocode.placeId,
          mainText: location.mainText ?? geocode.mainText,
          secondaryText: location.secondaryText,
          types: location?.types ?? geocode?.types,
          center: geocode?.center,
          bounds: geocode?.bounds,
          city: geocode?.city,
          region: geocode?.region,
          country: geocode?.country,
          fullName: location?.fullName ?? geocode?.fullName,
        })
      }
    }

    clearBounds()

    if (dates) {
      updateDates(dates)
    }

    if (guests) {
      updateGuests(guests)
    }

    if (filters) {
      updateFilter('delivery', filters.delivery)
      updateFilter('drivable', filters.drivable)
      updateFilter('towable', filters.towable)
    }
  }

  const isMenuFilterApplied = useState('isMenuFilterApplied', () => false)

  const applyMenuFilter = () => {
    isMenuFilterApplied.value = true
  }

  const resetMenuFilter = () => {
    isMenuFilterApplied.value = false
  }

  const searchQuery = extractSearchQuery(query)

  // const location = queryStringToLocation(searchQuery)
  const bounds = queryStringToBounds(searchQuery)

  const exportedSearchParameters: SearchParameters = {
    location: {},
    bounds,
    dates: queryStringToDates(searchQuery),
    guests: queryStringToGuests(searchQuery),
    filters: queryStringToSearchFilters(searchQuery),
    sort: queryStringToSortOrder(searchQuery),
  }

  // Priority for setting location
  // 1. Set bounding coords (neLat, neLng, swLat, swLng)
  if (bounds?.hasBounds) {
    exportedSearchParameters.location = boundsToLocation('', bounds) ?? {}
  }
  else if (searchQuery.SearchAddress) {
    // 2. Set location from searchAdress
    const geocodedAddress = await geocode(searchQuery.SearchAddress)

    if (geocodedAddress) {
      const location = parseGoogleGeocode({
        fullName: searchQuery.SearchAddress,
        geocode: geocodedAddress,
      })
      exportedSearchParameters.location = location
    }
  }
  else {
    exportedSearchParameters.location = geolocationToLocation(geolocation.value) ?? {}
  }

  const discoverCookie = useCookie(COOKIES.discoverDeliveryTooltip)

  const exportedDiscoverDelivery = Boolean(
    !discoverCookie.value && !exportedSearchParameters.filters.delivery,
  )

  searchParameters.value = exportedSearchParameters
  showDiscoverDelivery.value = exportedDiscoverDelivery

  return {
    provide: {
      search: {
        parameters: searchParameters.value,
        showDiscoverDelivery: showDiscoverDelivery.value,
        isMenuFilterApplied,
        applyMenuFilter,
        resetMenuFilter,
        closeDiscoverDelivery,
        updateRoute,
        updateParameters,
        clearBounds,
        updateBounds,
        clearLocation,
        updateLocationFields,
        updateDates,
        updateGuests,
        updateFilters,
        updateFilter,
        updateSortOrder,
        searchParametersToApiRequest: () =>
          searchParametersToApiRequest(searchParameters.value),
        searchCriteriaToSearchParameters: (searchCriteria: SearchCriteria) =>
          searchCriteriaToSearchParameters(searchCriteria, searchParameters.value),
        parseGooglePlace,
      },
    },
  }
})
