import { captureError } from '~/lib/logger'

/**
 * This composable manages a user's favourited RVs and collections.
 *
 * Question: why do we have separate endpoints for different methods?
 * I.e.: `me/update-collection` instead of `me/collection` with a PUT method.
 */
export default function useFavouriteRVs() {
  const collections = useCollections()
  const { isLoggedIn, user } = useAuthentication()
  const {
    getUserCollections,
    createUserCollection,
    updateUserCollection,
    removeUserCollection,
    addRvToCollection: addRvToFavourites,
    removeRvFromCollection: removeRvFromFavourites,
  } = useRentalsApi()

  const loading = ref(false)

  const favourites = computed(() => collections.value.map((collection) => collection.Items).flat())

  /**
   * Fetches a user's collections and saves the result in state.
   */
  const getCollections = async () => {
    if (!isLoggedIn.value) return

    if (loading.value) return

    try {
      loading.value = true
      const { data } = await getUserCollections()

      if (data.value) {
        collections.value = data.value
      }
    }
    catch (error) {
      captureError(error)
    }
    finally {
      loading.value = false
    }
  }

  /**
   * Clears the collections from the state.
   */
  const clearCollections = () => {
    collections.value = []
  }

  /**
   * Creates a new collection and adds it to the state. Optionally adding an RV
   * in the process.
   */
  const createCollection = async (collectionName: string) => {
    if (!isLoggedIn.value || !user.value?.Id) return

    if (loading.value) return

    try {
      loading.value = true
      const { data } = await createUserCollection({
        CollectionName: collectionName,
        ProfileId: user.value?.Id,
      })

      if (data.value) {
        collections.value = [...collections.value, data.value]
      }

      return data.value
    }
    catch (error) {
      captureError(error)
    }
    finally {
      loading.value = false
    }
  }

  /**
   * Updates a collection and saves the result in state.
   */
  const updateCollection = async ({
    collectionID,
    newCollectionName,
  }: {
    collectionID: number
    newCollectionName: string
  }) => {
    if (!isLoggedIn.value || !user.value?.Id) return

    if (loading.value) return

    try {
      loading.value = true

      await updateUserCollection({
        Id: collectionID,
        CollectionName: newCollectionName,
        ProfileId: user.value?.Id,
      })

      const collection = collections.value.find((collection) => collection.Id === collectionID)

      if (collection) {
        collection.CollectionName = newCollectionName
      }
    }
    catch (error) {
      captureError(error)
    }
    finally {
      loading.value = false
    }
  }

  /**
   * Deletes a collection and removes it from the state.
   */
  const deleteCollection = async (collectionID: number) => {
    if (!isLoggedIn.value || !user.value?.Id) return

    if (loading.value) return

    try {
      loading.value = true

      await removeUserCollection({ Id: collectionID })

      collections.value = collections.value.filter((collection) => {
        return collection.Id !== collectionID
      })
    }
    catch (error) {
      captureError(error)
    }
    finally {
      loading.value = false
    }
  }

  /**
   * Adds an RV to a collection and saves the result in state.
   */
  const addRvToCollection = async ({ rvID, collectionID }: { rvID: string, collectionID?: number }) => {
    if (!isLoggedIn.value || !user.value?.Id) return

    if (loading.value) return

    try {
      loading.value = true

      const collectionListID = collectionID || collections.value?.[0]?.Id

      const { data } = await addRvToFavourites({
        RvId: rvID,
        CollectionId: collectionListID,
        ProfileId: user.value?.Id,
      })

      if (data.value) {
        collections.value.find((collection) => collection.Id === collectionListID)?.Items?.push(data.value)
      }
    }
    catch (error) {
      captureError(error)
    }
    finally {
      loading.value = false
    }
  }

  /**
   * Removes an RV from a collection and saves the result in state.
   */
  const removeRvFromCollection = async ({ RvId, CollectionId }: { RvId: number, CollectionId: number }) => {
    if (!isLoggedIn.value) return

    if (loading.value) return

    try {
      loading.value = true

      await removeRvFromFavourites({
        Id: RvId,
      })

      // Remove the favourite from the right collection.
      collections.value = collections.value?.map((collection) => collection.Id === CollectionId ? { ...collection, Items: (collection.Items || []).filter((item) => item.Id !== RvId) } : collection)
    }
    catch (error) {
      captureError(error)
    }
    finally {
      loading.value = false
    }
  }

  /**
   * Checks if an RV is favourited in any of the collections.
   */
  const isRvFavourited = (rvId: string) => {
    return favourites.value.find((favourite) => favourite && favourite.RVId === rvId)
  }

  return {
    collections,
    favourites,
    loading,
    getCollections,
    clearCollections,
    createCollection,
    updateCollection,
    deleteCollection,
    addRvToCollection,
    removeRvFromCollection,
    isRvFavourited,
  }
}
