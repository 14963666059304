import { v4 as uuidv4 } from 'uuid'
import { COOKIES } from '~/constants'
import { getSegmentAnonymousId } from '~/lib/tracking/segmentTrack'

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  const clients = runtimeConfig.public.openFetch
  const localFetch = useRequestFetch()
  const { isMobileOrTablet } = useDevice()
  const { rawToken } = useAuthState()
  const platformCookie = useCookie(COOKIES.platform)
  const headers = useRequestHeaders()
  const { $captureError } = useNuxtApp()

  return {
    // All clients will be augmented with these headers
    provide: Object.entries(clients).reduce((acc, [name, options]) => ({
      ...acc,
      [name]: createOpenFetch((localOptions) => {
        if (name === 'gis') {
          return {
            ...options,
            ...localOptions,
            onRequest({ options }) {
              options.query = {
                ...options.query,
                apiKey: runtimeConfig.public.googleMapsApiKey,
              }
            },
            onRequestError({ error }) {
              $captureError(error, {
                location: '$gis onRequestError',
              })
            },
            onResponseError({ response }) {
              $captureError(response, {
                location: '$gis onResponseError',
              })
            },
          }
        }

        else {
          return {
            ...options,
            ...localOptions,
            onRequest({ options }) {
              options.headers = {
                ...options.headers,
              } as Record<string, string>

              // This is the version of the API we are using.
              options.headers['RVZ-version'] = runtimeConfig.public.rentalApiVersion

              if (rawToken.value) {
                options.headers['Authorization'] = `Bearer ${rawToken.value}`
              }

              // User Agent.
              const userAgent = headers['user-agent']

              if (userAgent) {
                options.headers['User-Agent'] = userAgent
              }

              // Cloudflare Headers.
              const cfCountry = headers['cf-ipcountry']
              const cfRegion = headers['cf-region']
              const cfCity = headers['cf-ipcity']

              if (cfCountry) {
                options.headers['RVZ-ipcountry'] = cfCountry
              }

              if (cfRegion) {
                options.headers['RVZ-region'] = cfRegion
              }

              if (cfCity) {
                options.headers['RVZ-ipcity'] = cfCity
              }

              options.headers['RVZ-Correlation-Id'] = uuidv4()

              options.headers['Platform'] = platformCookie.value ?? (isMobileOrTablet ? 'mobile_web' : 'web')

              // Client-only headers.
              if (import.meta.client) {
                options.headers['RVZ-Anonymous-Id'] = getSegmentAnonymousId()

                options.credentials = 'include'
              }
            },
            onRequestError({ error }) {
              if (error.name === 'AbortError') {
                return
              }

              $captureError(error, {
                location: '$rental onRequestError',
              })
            },
            onResponseError({ response }) {
              $captureError(response, {
                location: '$rental onResponseError',
              })
            },
          }
        }
      }, localFetch),
    }), {}),
  }
})
